import * as React from 'react'
import styled from '@emotion/styled'
import Image from '../components/img/404.png'
import { H1, Button } from '@mui/material'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <Container>
      <img src={Image} alt="404" />
      Sivua ei löytynyt
      <p>
        Etsimääsi sivua ei löytynyt. Sivu on poistettu, siirretty tai osoite on
        väärin. Tarkista osoitekenttä ja yritä uudelleen tai palaa etusivulle.
      </p>
    </Container>
  )
}

const Container = styled.div`
  max-width: 650px;
  margin: auto;
  text-align: center;
  padding: 50px;
`

const CustomButton = styled(Button)`
  margin-top: 25px;
`

export default NotFoundPage
